import React, { useState, useEffect } from 'react';
import './App.css';


const seededRandom = (seed) => {
  let x = Math.sin(seed++) * 10000;
  return x - Math.floor(x);
};

const getGameId = () => {
  const startDate = new Date('2023-07-03');
  const currentDate = new Date();
  
  // Subtract the start date from the current date and convert it to days
  const differenceInTime = currentDate.getTime() - startDate.getTime();
  const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));

  return differenceInDays + 1;
};

const sumOfDigitsRule = Math.floor(seededRandom(getGameId()) * 20) + 10;





function App() {
  const [password, setPassword] = useState('');
  const [level, setLevel] = useState(0);
  const [start, setStart] = useState(false);
  const [successRules, setSuccessRules] = useState([]);
  const [gameId, setGameId] = useState(getGameId());
  const [time, setTime] = useState(0);
  const [isGameCompleted, setIsGameCompleted] = useState(false);
  const [country, setCountry] = useState('');
  const [countryFlag, setCountryFlag] = useState('');


  useEffect(() => {
    fetch('https://restcountries.com/v3.1/all')
      .then(response => response.json())
      .then(data => {
        const seed = getGameId();
        const country = data[Math.floor(seededRandom(seed) * data.length)];
        setCountry(country.name.common);
        console.log(country.name.common)
        setCountryFlag(country.flags.png);
      })
      .catch(error => console.error(error));
  }, []);

  const getColorHex = () => {
    let hex = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += hex[Math.floor(seededRandom(gameId) * 16)];
    }
    return color;
  };

  const rules = [
    {
      rule: 'Your password must be at least 8 characters.',
      validator: password => password.length >= 8
    },
    {
      rule: 'Your password must include a number.',
      validator: password => /\d/.test(password)
    },
    {
      rule: 'Your password must include a prime number.',
      validator: password => {
        const numbers = password.match(/\d+/g);
        if (numbers) {
          for (let number of numbers) {
            let isPrime = true;
            for (let i = 2, max = Math.sqrt(number); i <= max; i++) {
              if (number % i === 0) {
                isPrime = false;
                break;
              }
            }
            if (isPrime && number > 1) {
              return true;
            }
          }
        }
        return false;
      }
    },
    {
      rule: 'Your password must include a lowercase letter.',
      validator: password => /[a-z]/.test(password)
    },
    {
      rule: 'Your password must include an uppercase letter.',
      validator: password => /[A-Z]/.test(password)
    },
    {
      rule: `The digits in your password must add up to ${sumOfDigitsRule}.`,
      validator: password => {
        const sum = password.split('').reduce((total, char) => {
          if (/\d/.test(char)) {
            total += parseInt(char, 10);
          }
          return total;
        }, 0);
        return sum === sumOfDigitsRule;
      }
    },
    {
      rule: 'Your password must include a popular programming language.',
      validator: password => {
        const languages = ['python', 'java', 'javascript', 'c++', 'c#', 'php', 'go', 'rust', 'kotlin', 'swift'];
        return languages.some(lang => password.toLowerCase().includes(lang));
      }
    },
    {
      rule: 'Your password must include a special character.',
      validator: password => /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password)
    },
    {
      rule: 'Your password must not include !, @.',
      validator: password => !/[!@]/.test(password)
    },
    {
      rule: 'Your password must include a three-letter acronym.',
      validator: password => /[A-Z]{3}/.test(password)
    },
    {
      rule: 'Your password must include a planet from our solar system.',
      validator: password => /(Mercury|Venus|Earth|Mars|Jupiter|Saturn|Uranus|Neptune)/i.test(password)
    },
    {
      rule: 'Your password must include a Greek alphabet character.',
      validator: password => /(alpha|beta|gamma|delta|epsilon|zeta|eta|theta|iota|kappa|lambda|mu|nu|xi|omicron|pi|rho|sigma|tau|upsilon|phi|chi|psi|omega)/i.test(password)
    },
    {
      rule: 'Your password must include a word with at least 5 vowels.',
      validator: password => /\b(?:[aeiou]*[^aeiou]*){5,}/i.test(password)
    },
    {
      rule: 'Your password must include a palindrome.',
      validator: password => /(\w)?(\w)\w?\2\1/i.test(password)
    },
    {
      rule: 'Your password must include a power of 2 in binary.',
      validator: password => {
        const powerOf2Pattern = /(10*0)/;
        return powerOf2Pattern.test(password);
      }
    },
    {
      rule: 'Your password must include a color name.',
      validator: password => {
        const colorNames = ['red', 'orange', 'yellow', 'green', 'blue', 'indigo', 'violet', 'black', 'white', 'gray'];
        return colorNames.some(color => password.toLowerCase().includes(color));
      }
    },
    {
      rule: 'Your password must include an even number.',
      validator: password => {
        const evenNumberPattern = /[02468]/;
        return evenNumberPattern.test(password);
      }
    },
    {
      rule: 'Your password must include an odd number.',
      validator: password => {
        const oddNumberPattern = /[13579]/;
        return oddNumberPattern.test(password);
      }
    },
    {
      rule: 'Your password must include the length of your password.',
      validator: password => password.includes(password.length.toString())
    },
    {
      rule: 'The length of your password must be a prime number.',
      validator: password => {
        const isPrime = num => {
          for(let i = 2; i < num; i++)
            if(num % i === 0) return false;
          return num > 1;
        }
    
        return isPrime(password.length)
      }
    },
    {
      rule: 'Your password must include this color in hex.',
      validator: password => {    
        const colorHex = getColorHex(); 
        return password.includes(colorHex.toLowerCase());
      },
      color:getColorHex()
    },
    {
      rule: 'Your password must include a leap year.',
      validator: password => {
        // Function to check if a year is a leap year
        const isLeap = year => ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0);
        for(let year = 2020; year > 1900; year--){
          if(password.includes(year.toString()) && isLeap(year)) return true;
        }
        return false;
      }
    },
    {
      rule: 'Your password must include the name of this country.',
      validator: password => {
        return password.includes(country);
      }
    },
    {
      rule: 'Your password must include a two letter symbol from the periodic table.',
      validator: password => {
        const periodicSymbols = ["He", "Li", "Be", "Ne", "Na", "Mg", "Al", "Si", "Cl", "Ar", "Ca", "Sc", "Ti", "Cr", "Mn", "Fe", "Co", "Ni", "Cu", "Zn", "Ga", "Ge", "As", "Se", "Br", "Kr", "Rb", "Sr", "Zr", "Nb", "Mo", "Tc", "Ru", "Rh", "Pd", "Ag", "Cd", "In", "Sn", "Sb", "Te", "I", "Xe", "Cs", "Ba", "La", "Ce", "Pr", "Nd", "Pm", "Sm", "Eu", "Gd", "Tb", "Dy", "Ho", "Er", "Tm", "Yb", "Lu", "Hf", "Ta", "W", "Re", "Os", "Ir", "Pt", "Au", "Hg", "Tl", "Pb", "Bi", "Th", "Pa", "U", "Np", "Pu", "Am", "Cm", "Bk", "Cf", "Es", "Fm", "Md", "No", "Lr", "Rf", "Db", "Sg", "Bh", "Hs", "Mt", "Ds", "Rg", "Cn", "Nh", "Fl", "Mc", "Lv", "Ts", "Og"];
        return periodicSymbols.some(symbol => password.includes(symbol));
      }
    }
    
    // ...and so on for the rest of the rules
  ];


  
  

  

  const selectedRules = [];
  for (let i = 0; i < 20; i++) {
    const index = Math.floor(seededRandom(gameId) * rules.length);
    selectedRules.push(rules[(index+i)%rules.length]);
  }

  useEffect(() => {
    let timer = setInterval(() => setTime(time => time + 1), 1000);
    
    if (isGameCompleted && timer) {
      clearInterval(timer);
    }
  
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [isGameCompleted]);
  

  
  

  useEffect(() => {
    if (password !== '' && start) {
      const newSuccessRules = [];
      for (let i = 0; i <= level; i++) {
        if (selectedRules[i].validator(password)) {
          newSuccessRules.push(i);
        }
      }
      setSuccessRules(newSuccessRules);
      if (newSuccessRules.includes(level) && level < selectedRules.length - 1) {
        setLevel(level + 1);
      }
    }
  }, [password, level, start]);

  useEffect(() => {
    console.log(successRules.length)
    console.log(selectedRules.length)
    if (successRules.length === selectedRules.length) {
      setIsGameCompleted(true);
    }
  }, [successRules]);

  const handleInputChange = e => {
    if (!start) {
      setStart(true);
    }
    setPassword(e.target.value);
  };

  return (
    <div className="App">
      <h1 className="header">The Password Game</h1>
      
      <p className="description">The Password Game is a daily Wordle-like puzzle that challenges you to create a new password based on specific criteria and rules. Password Game is an enhanced version of the popular and trendy Password Game but with simplified rules and speedrun feature.</p>
      <div className="top-of-input">
        <p className="gameId">Password #{ gameId }</p>
        { !isGameCompleted && <p className="timer">Time: {Math.floor(time / 60).toString().padStart(2, '0') + ':' + (time % 60).toString().padStart(2, '0')}</p>}
      </div>
      <input className="password-input" type="text" onChange={handleInputChange} placeholder="Start typing..." />
  
      {start && successRules.length === selectedRules.length && <div className="final"><p className="success-message">Password is ready!</p><p>Your time: {Math.floor(time / 60).toString().padStart(2, '0') + ':' + (time % 60).toString().padStart(2, '0')}</p></div> }
      {
        start && selectedRules.slice(0, level + 1).reverse().map((rule, index) => (
          <div key={index} className={`rule ${successRules.includes(level - index) ? "rule-success" : "rule-failure"}`}>
            <span className="rule-number">Rule {level - index + 1}</span>
            {rule.rule}
            {rule.rule.includes('color in hex') && <div style={{width: '50px', height: '50px', backgroundColor: rule.color}}/>}
            {rule.rule.includes('country') && <img src={countryFlag} alt='Country flag' style={{width: '80px', height: '80px'}}/>}
            {successRules.includes(level - index) ? <span className="tick">✔️</span> : <span className="cross">❌</span>}
          </div>
        ))
      }
      <p>A new game puzzle is available every day!</p>
    </div>
  );
}

export default App;
